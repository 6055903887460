<template>
  <v-container id="mgrtools" fluid tag="section">
    <v-skeleton-loader v-if="firstLoad" :loading="loading" type="table"></v-skeleton-loader>
    <v-row v-else>
      <v-col cols="4">
        <v-row>
          <v-col cols="12">
            <!--item-key="symbol"-->
            <v-data-table dense width="100%" :headers="headers" :items="items" class="elevation-1" hide-default-header
              hide-default-footer>
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>Coin Base</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-dialog :loading="loading" v-model="dialog" max-width="400px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-row>
                        <v-col cols="6" sm="6" md="6">
                          <v-btn :loading="loading" color="info" dark class="mb-2" v-bind="attrs" v-on="on">
                            New
                          </v-btn>
                        </v-col>
                      </v-row>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">{{ formTitle }}</span>
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="6" sm="6" md="6">
                              <v-text-field v-model="editedItem.account" label="Account"></v-text-field>
                            </v-col>
                            <v-col cols="6" sm="6" md="6">
                              <v-text-field v-model="editedItem.symbol" label="Symbol"></v-text-field>
                            </v-col>
                            <v-col cols="6" sm="6" md="6">
                              <v-text-field v-model="editedItem.base" label="Base"></v-text-field>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="close">
                          Cancel
                        </v-btn>
                        <v-btn color="blue darken-1" text @click="save">
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <confirm-dialog text="Are you sure to delete this item?" :dialogFlag="dialogDelete"
                    :cancelFunc="closeDelete" :confirmFunc="deleteItemConfirm"></confirm-dialog>
                  <confirm-dialog text="Are you sure to update new BASE??" :dialogFlag="dialogUpdate"
                    :cancelFunc="closeUpdate" :confirmFunc="onUpdate"></confirm-dialog>
                </v-toolbar>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
                <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn :disabled="isOriginParams" :loading="loading" color="info" @click="tryToUpdate">
              Update
            </v-btn>
            <v-btn :loading="loading" color="info" class="ml-2" @click="loadCurrentBase">
              Reset
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="4">
        <v-row>Current Base: </v-row>
        <v-row>
          <pre>{{ originParams }}</pre>
        </v-row>
      </v-col>

      <v-col cols="4">
        <v-row>New Base: </v-row>
        <v-row>
          <pre>{{ params }}</pre>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  update_base_balances,
  getHistoryBalances,
  get_base_balances,
  myMixins,
} from "@/api";

import { mm_group, lchan_group, rp_group, group_names, groups, } from '@/api/constants'
import ConfirmDialog from "@/components/base/ConfirmDialog.vue";

export default {
  mixins: [myMixins],

  components: {
    ConfirmDialog,
  },

  created: function () {
    this.loadCurrentBase();
  },
  methods: {
    loadCurrentBase: function () {
      //TODO

      this.loading = true;

      try {
        get_base_balances().then((base_resp) => {
          console.log("base_resp:", base_resp.data);
          const data = base_resp.data[base_resp.data.length - 1].updated_items.update_items;

          this.items = [];
          for (let key in data) {
            for (let key2 in data[key]) {
              const item = data[key][key2];

                this.items.push({'account': key, 'symbol': key2, 'base': data[key][key2]});
              
            }
          }

          this.originParams = this.genParamsFromItems(this.items);

          if (this.firstLoad) {
            this.firstLoad = false;
          }
          this.loading = false;

        });

      } catch (err) {
        console.error(err);
        this.logout();
      }
      

      /*
      getHistoryBalances()
        .then((resp) => {
          this.items = [];

          resp.baseLst.forEach((item) => {
            const fValue = parseFloat(item.base);
            if (fValue > 0) {
              this.items.push(item);
            }
          });

          if (this.firstLoad) {
            this.firstLoad = false;
          }
          this.loading = false;
          this.originParams = this.genParamsFromItems(this.items);
        })
        .catch((reason) => {
          console.error(reason);
          this.logout();
        });
        */
        
    },

    genParamsFromItems: function (items) {
      if (items !== undefined && items !== null) {
        const data = {};

        group_names.forEach((group_name) => {
          data[group_name] = {}
        })

        items.forEach((item, index, arr) => {
          const fValue = parseFloat(item.base);
          if (fValue > 0) {
            data[item.account][item.symbol] = fValue;
          }
        });
        return JSON.stringify({ update_balances: data }, null, 4);
      }
      return "";
    },

    onUpdate: function () {
      try {
        let _t = typeof this.params;
        if (_t == "string") {
          this.params = JSON.parse(this.params);
        } else if (_t === "object") {
        }

        console.log(this.params);

        update_base_balances(this.params);
        
      } catch (error) { }

      this.closeUpdate()
    },

    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.items.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.items[this.editedIndex], this.editedItem);
        this.items.push({});
        this.items.pop();
      } else {
        this.items.push(this.editedItem);
      }

      this.close();
    },

    tryToUpdate() {
      this.dialogUpdate = true;
    },

    closeUpdate() {
      this.dialogUpdate = false;
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },

    dialogDelete(val) {
      val || this.closeDelete();
    },

    dialogUpdate(val) {
      val || this.closeUpdate();
    },

    items(val) {
      this.params = this.genParamsFromItems(val);
      this.isOriginParams = this.params === this.originParams;
    },
  },
  data: function () {
    return {
      firstLoad: true,
      loading: true,
      dialog: false,
      dialogDelete: false,
      dialogUpdate: false,
      editedItem: {
        symbol: "",
        base: 0,
      },
      defaultItem: {
        symbol: "",
        base: 0,
      },
      editedIndex: -1,
      selected: [],
      originParams: "",
      params: "",
      isOriginParams: true,
      headers: [
        { text: "Account", value: "account" },
        { text: "Symbol", value: "symbol" },
        { text: "Base", value: "base" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      items: [],
    };
  },
};
</script>
